(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.view.controller:EventsViewCtrl
   *
   * @description
   *
   */
  angular
    .module('events.view')
    .controller('EventsViewCtrl', EventsViewCtrl);

  function EventsViewCtrl(API_URL, $stateParams, $filter, $http, Restangular) {
    var vm = this;

    vm.event = {};
    vm.event_stats = {};
    vm.pax_stats = {};
    vm.vehicle_stats = {};
    vm.depositSummary = [];

    Restangular.one('events', $stateParams.eventId).get().then(function (response) {
      vm.event = response.data;

      var format = 'ddd, Do MMM Y';
      var starts = moment(vm.event.starts_at);
      var ends = moment(vm.event.ends_at);

      vm.fromNow = starts.fromNow();
      vm.hasStarted = starts.isBefore();
      vm.startDate = starts.format(format);
      vm.endDate = ends.format(format);
    });

    // Event stats
    Restangular.one('events', $stateParams.eventId).one('event-stats').get().then(function (response) {

      var data = response.data[0];
      data.entries_total = $filter('dollars')(data.entries_total);
      data.entries_paid = $filter('dollars')(data.entries_paid);
      data.donations_min_paid = $filter('dollars')(data.donations_min_paid);
      data.donations_min_total = $filter('dollars')(data.donations_min_total);
      data.donations_paid = $filter('dollars')(data.donations_paid);
      data.donations_total = $filter('dollars')(data.donations_total);
      data.meals_paid = $filter('dollars')(data.meals_paid);
      data.meals_total = $filter('dollars')(data.meals_total);
      data.option_paid = $filter('dollars')(data.option_paid);
      data.option_total = $filter('dollars')(data.option_total);

      vm.event_stats = data;
      vm.totalVehicles = vm.event_stats.entrants + vm.event_stats.officials + vm.event_stats.supports;

      var depositSummary = [];
      $http.get(API_URL + '/deposit-types').then(function(response) {
          angular.forEach(response.data.data, function(dt) {

            if (dt.category === 'donations' || dt.category === 'meals' || dt.category === 'entry' || dt.category === 'option') {
              return;
            }

            depositSummary.push({
              name: dt.name,
              amount: data.deposit_summary[dt.category]
            });
          });
        vm.depositSummary = depositSummary;
      });
    });

    // Vehicle people stats
    $http.get(API_URL + '/events/'+$stateParams.eventId+'/vehicles?inc=VehiclePeople').then(function(response) {

      // stats for people and vehicles in [both parts, part 1, part 2]
      var pax = {
        entrant: [0, 0, 0],
        support: [0, 0, 0],
        official: [0, 0, 0],
        total: [0, 0, 0]
      }
      var vehicles = {
        entrant: [0, 0, 0],
        support: [0, 0, 0],
        official: [0, 0, 0],
        total: [0, 0, 0]
      }
      var parts;
      var s = 0;

      angular.forEach(response.data.data, function(v) {

        if (v.type === 'caterer') { // caterers not needed
          return
        }

        // Handle vehicles without people
        if (v.vehicle_people === undefined || v.vehicle_people.length === 0) {
          vehicles[v.type][0]++ // a vehicle without people is assumed to be doing the whole event
          vehicles.total[0]++
          return
        }

        // count the people per part for this vehicle [both, 1, 2]
        parts = [0, 0, 0]

        // tally the people per vehicle type and event part
        angular.forEach(v.vehicle_people, function (vp) {
          pax[v.type][vp.participation]++
          pax.total[vp.participation]++
          parts[vp.participation]++
        })

        // tally vehicle counts by type and event part
        if (parts[0] === 0 && parts[2] === 0) { // part 1 only
          vehicles[v.type][1]++
          vehicles.total[1]++
        } else if (parts[0] === 0 && parts[1] === 0) { // part 2 only
          vehicles[v.type][2]++
          vehicles.total[2]++
        } else { // both
          vehicles[v.type][0]++
          vehicles.total[0]++
        }
      });

      vm.pax_stats = pax;
      vm.vehicle_stats = vehicles;
    });
  }
}());
